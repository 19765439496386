import { dbCollections } from '@/utils/FirebaseCollections';
import { mongodbCrudOperations } from '@/utils/MongoQueries/crudOperations/crudOperations';

export const getTours = async ({ commit }, payload) => {
  let additionalData = {
    "isProjectTour" : {
      tourModalTitle : "have_brief_tour_project",
      tourModalMessage : "look_how_to_create_project"
    },
    "isTaskTour": {
      tourModalTitle : "have_brief_task",
      tourModalMessage : "look_how_to_create_task"
    },
    "isProjectAndNavbarTour": {
      tourModalTitle : "have_brief_tour_alianhub",
      tourModalMessage : "look_how_to_create"
    },
    "isProjectViewTour" : {
      tourModalTitle : "have_brief_tour_alianhub_view",
      tourModalMessage : "look_how_to_create"
    },
    "isProjectLeftViewTour" : {
      tourModalTitle : "have_brief_tour_alianhub_left_side",
      tourModalMessage : "look_how_to_create"
    }
  }

  try {
    let obj = {
      type: "find",
      collection: dbCollections.TOURS,
      data: [{}],
      global: true,
    }
    mongodbCrudOperations(obj).then((res) => {
      let tourList;
      if (res?.length <= 0) return;
      tourList = res;
      let tourStatus = payload;

      for (let tour of tourList) {
        if (Object.hasOwnProperty.call(tourStatus, tour.id)) {
          tour.isCompleted = tourStatus[tour.id];
        }
        if(additionalData[tour.id]){
          tour.tourModalTitle = additionalData[tour.id].tourModalTitle;
          tour.tourModalMessage = additionalData[tour.id].tourModalMessage;
        }
      }
      commit('mutateTours',tourList)
      
    }).catch((error) => {
      console.error("Error GET TOURS GET", error)
    })
    // tourList.value.sort((b,a)=>{return a.isCompleted - b.isCompleted});
  } catch (error) {
    console.error(`ERROR in getTourDetails`, error);
  }
};
