import { driver } from 'driver.js';
import "driver.js/dist/driver.css";
import * as mongoQuery from "@/utils/MongoQueries/crudOperations/crudOperations";
import createTaskTour from '@/utils/tourslist/createTaskTour.json';
import projectViewAndNavbar from '@/utils/tourslist/projectViewAndNavbar.json';
import createProjectStep1 from '@/utils/tourslist/createProjectStep1.json';
import createProjectStep2 from '@/utils/tourslist/createProjectStep2.json';
import createProjectStep3 from '@/utils/tourslist/createProjectStep3.json';
import createProjectStep4 from '@/utils/tourslist/createProjectStep4.json';
import projectViewsandOptions from '@/utils/tourslist/projectViewsandOptions.json';
import projectLeftSide from '@/utils/tourslist/projectLeftSide.json';
import { dbCollections } from '@/utils/FirebaseCollections';
import { BSON } from "realm-web";
import { useStore } from 'vuex';
import { useGetterFunctions } from '@/composable';
import { inject,computed } from 'vue';

export function tourHepler() {
    let driverObj = null;
    const {getters,commit} = useStore();
    const companyUserDetail = computed(() => getters['settings/companyUserDetail'])
    const {getUser} = useGetterFunctions();
    const userId = inject("$userId");
    const clientWidth = inject("$clientWidth");
    
    const handleTours = (tNum = 0) => {

        // startProjectTour()
        let currentTour = {
            popoverClass: 'driverjs-theme custome_projects_driver',
            showProgress: false,
            overlayColor: 'black',
            showButtons: ['next', 'previous','close'],
            disableActiveInteraction : true,
            smoothScroll: true,
            nextBtnText: 'Next',
            prevBtnText: 'Previous',
            doneBtnText: 'Next',
        };
        if(tNum === "isTaskTour"){
            currentTour = {
                ...currentTour,
                steps: [
                    ...createTaskTour
                ],
                onCloseClick: () => closeTaskButtonClick(),
                onNextClick: (element, step, options) => onNextClickHandler('isTaskTour',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('isTaskTour',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        closeTaskButtonClick()
                        driverObj.destroy();
                    }
                },
            }
        } else if(tNum === "isProjectAndNavbarTour") {
            currentTour = {
                ...currentTour,
                steps: [
                    ...projectViewAndNavbar
                ],
                onCloseClick: () => closeButtonClick(),
                onNextClick: (element, step, options) => onNextClickHandler('',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        updateTourStatusInUser('isProjectAndNavbarTour');
                        driverObj.destroy();
                    }
                },
            }
        } else if (tNum === "isProjectTour") {
            currentTour = {
                ...currentTour,
                steps: [
                    ...createProjectStep1
                ],
                onCloseClick: () => {driverObj.destroy();updateTourStatusInUser('isProjectTour');},
                onNextClick: (element, step, options) => onNextClickHandler('createproject',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('createproject',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        driverObj.destroy();
                    }
                },
            }
        } else if (tNum === 'isProjectTour1') {
            currentTour = {
                ...currentTour,
                steps: [
                    ...createProjectStep2
                ],
                onCloseClick: () => {driverObj.destroy();updateTourStatusInUser('isProjectTour');},
                onNextClick: (element, step, options) => onNextClickHandler('createprojectform',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('createprojectform',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        driverObj.destroy();
                    }
                },
            }
        } else if (tNum === 'isProjectTour2') {
            currentTour = {
                ...currentTour,
                steps: [
                    ...createProjectStep3
                ],
                onCloseClick: () => {driverObj.destroy();updateTourStatusInUser('isProjectTour');},
                onNextClick: (element, step, options) => onNextClickHandler('createprojecttasktype',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('createprojecttasktype',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        driverObj.destroy();
                    }
                },
            }
        } else if (tNum === 'isProjectTour3') {
            currentTour = {
                ...currentTour,
                steps: [
                    ...createProjectStep4
                ],
                onCloseClick: () => {driverObj.destroy();updateTourStatusInUser('isProjectTour');},
                onNextClick: (element, step, options) => onNextClickHandler('createprojectstepbtn',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('createprojectstepbtn',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        driverObj.destroy();
                    }
                },
            }
        } else if(tNum === 'isProjectViewTour') {
            currentTour = {
                ...currentTour,
                steps: [
                    ...projectViewsandOptions
                ],
                onCloseClick: () => {driverObj.destroy();updateTourStatusInUser('isProjectViewTour');},
                onNextClick: (element, step, options) => onNextClickHandler('projectview',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('projectview',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        updateTourStatusInUser('isProjectViewTour');
                        driverObj.destroy();
                    }
                },
            }
        } else if(tNum === 'isProjectLeftViewTour') {
            currentTour = {
                ...currentTour,
                steps: [
                    ...projectLeftSide
                ],
                onCloseClick: () => {driverObj.destroy();updateTourStatusInUser('isProjectLeftViewTour');},
                onNextClick: (element, step, options) => onNextClickHandler('projectlistleft',element, step, options),
                onPrevClick: (element, step, options) => onPrevClickHanler('projectlistleft',element, step, options),
                onDestroyStarted: () => {
                    if (!driverObj.hasNextStep()) {
                        updateTourStatusInUser('isProjectLeftViewTour');
                        driverObj.destroy();
                    }
                },
            }
        }
        return currentTour;
    
    }
    const closeButtonClick = () => {
        updateTourStatusInUser('isProjectAndNavbarTour');
        driverObj.destroy();
    }
    const closeTaskButtonClick = () => {
        updateTourStatusInUser('isTaskTour');
        driverObj.destroy();
    }
    const closeIconTaskHandler = (tourKey) => {
        updateTourStatusInUser(tourKey)
    }
    
    const updateTourStatusInUser = (currentTour="isProjectAndNavbarTour") => {
        try {
            let tourObject = {
                ...(getUser(userId.value).tourStatus && getUser(userId.value).tourStatus),
                [currentTour]: true
            }
            const query = {
                type: "updateOne",
                collection: dbCollections.USERS,
                global: true,
                data: [
                    { _id: BSON.ObjectID(userId.value)},
                    { $set: { tour: tourObject } },
                    { upsert: true }
                ]
            };
            mongoQuery.mongodbCrudOperations(query).then(()=>{
                commit("ToursData/mutateSingleTours",{
                    id:currentTour,
                    isCompleted: true
                });
            }).catch((e)=>{console.error(e);})
        } catch (error) {
            console.error(error.message);
        }
    }
    
    const onNextClickHandler = (fromTour='') => {
        if(fromTour == '') {
            if(driverObj.isLastStep() == false) {
                if(driverObj.getActiveStep().element === '#time_sheet_driver' || driverObj.getActiveStep().element === '#tracker_time_sheet_driver') {
                    document.querySelector('#time_sheet_driver').click()
                } 
                setTimeout(()=>{
                    driverObj.moveNext()
                })
            } else {
                closeButtonClick()
            }
        } else if(fromTour == 'createproject' || fromTour == 'createprojectform' || fromTour == 'createprojecttasktype') {
            if(driverObj.isLastStep() == false) {
                driverObj.moveNext()
            } else {
                driverObj.destroy()
            }
        } else if(fromTour == 'createprojectstepbtn') {
            if(driverObj.isLastStep() == false) {
                driverObj.moveNext()
            } else {
                updateTourStatusInUser('isProjectTour');
                driverObj.destroy()
            }
        } else if(fromTour == 'projectview') {
            if(driverObj.isLastStep() == false) {
                if((document.getElementById('projectviewlist_driver') == null && driverObj.getActiveStep().element == '#embeddropdown') || driverObj.getActiveStep().element == '#projectviewlist_driver') {
                    if(document.getElementById('embeddropdown')) {
                        document.getElementById('embeddropdown_button').click();
                        setTimeout(()=>{
                            driverObj.moveNext()
                        },230)
                    }
                } else if(driverObj.getActiveStep().element == '#projectoptions_driver' || driverObj.getActiveStep().element == '#projectoptionslist_driver') {
                    document.getElementById('projectoptions_driver').click();
                    setTimeout(()=>{
                        driverObj.moveNext()
                    })
                } else if(driverObj.getActiveStep().element == '#searchfilterdropdown_driver' || driverObj.getActiveStep().element == '#dd_searchfilterdropdownoptions_driver') {
                    document.getElementById('searchfilterdropdown_driver').click();
                    setTimeout(()=>{
                        driverObj.moveNext()
                    })
                } else {
                    setTimeout(()=>{
                        driverObj.moveNext()
                    })
                }
            } else {
                updateTourStatusInUser('isProjectViewTour');
                driverObj.destroy()
            }
        } else if(fromTour == 'projectlistleft') {
            if(driverObj.isLastStep() == false) {
                if(driverObj.getActiveStep().element == '#projectrightsidedropdown_driver' || driverObj.getActiveStep().element == '#dd_project_filter_options') {
                    document.getElementById("projectrightsidedropdown_driver").click(); 
                }
                setTimeout(()=>{
                    driverObj.moveNext()
                })
            } else {
                updateTourStatusInUser('isProjectLeftViewTour');
                driverObj.destroy()
            }
        } else if(fromTour == 'isTaskTour') {
            if(driverObj.isLastStep() == false) {
                if((document.querySelector('#createtaskinput_driver') == null && driverObj.getActiveStep().element === '#createtask_driver')) {
                    document.querySelector('#createtask_driver').click()
                } 
                if(driverObj.getActiveStep()?.element === '#tasklist_driver' && document.getElementById('taskquickmenudriver') == null) {
                    closeTaskButtonClick();
                    return;
                }
                if(driverObj.getActiveStep()?.element === '#taskquickmenudriver' && document.getElementById('tasktoggle_driver') == null) {
                    closeTaskButtonClick();
                    return;
                }
                if(driverObj.getActiveStep().element === '#taskquickmenudriver' || driverObj.getActiveStep().element === '#taskquickmenu_driver') {
                    document.querySelector('#taskquickmenudriver').click()
                }
                if((document.querySelector('#subtasklist_driver .subTaskAddRemove') == null && driverObj.getActiveStep().element === '#taskquickmenu_driver') || driverObj.getActiveStep().element === '#subtasklist_driver') {
                    if(document.querySelector('#taskquickmenu_driver')) {
                        document.querySelector('#taskquickmenu_driver').click()
                    }
                }
                setTimeout(()=>{
                    driverObj.moveNext()
                })
            } else {
                closeTaskButtonClick()
            }
        }
    }
    const onPrevClickHanler = (fromTour="") => {
        if(fromTour == '') {
            if(driverObj.isFirstStep() == false) {
                if(driverObj.getActiveStep().element === '#company_dropdown_driver' || driverObj.getActiveStep().element === '#user_time_sheet_driver') {
                    document.querySelector('#time_sheet_driver').click()
                    setTimeout(()=>{
                        driverObj.movePrevious();
                    })
                } else {
                    driverObj.movePrevious();
                }
            }
        } else if(fromTour == 'createproject' || fromTour == 'createprojectform' || fromTour == 'createprojecttasktype' || fromTour == 'createprojectstepbtn') {
            if(driverObj.isFirstStep() == false) {
                driverObj.movePrevious();
            } else {
                driverObj.movePrevious();
            }
        } else if(fromTour == 'projectview') {
            if(driverObj.isFirstStep() == false) {
                if((document.getElementById('projectviewlist_driver') == null && driverObj.getActiveStep().element == '#projectviewfiles_driver') || driverObj.getActiveStep().element == '#projectviewlist_driver') {
                    if(document.getElementById('embeddropdown')) {
                        document.getElementById('embeddropdown_button').click();
                        setTimeout(()=>{
                            driverObj.movePrevious();
                        },230)
                    }
                } else if(driverObj.getActiveStep().element == '#projectviewfilter_driver' || driverObj.getActiveStep().element == '#projectoptionslist_driver'){
                    document.getElementById('projectoptions_driver').click();
                    setTimeout(()=>{
                        driverObj.movePrevious()
                    })
                } else if(driverObj.getActiveStep().element == '#projectviewassignee_driver' || driverObj.getActiveStep().element == '#dd_searchfilterdropdownoptions_driver' || driverObj.getActiveStep().element == '#end_tour_driver'){
                    document.getElementById('searchfilterdropdown_driver').click();
                    setTimeout(()=>{
                        driverObj.movePrevious()
                    })
                } else {
                    driverObj.movePrevious();
                }
            } else {
                driverObj.movePrevious();
            }
        } else if(fromTour == 'projectlistleft') {
            if(driverObj.isFirstStep() == false) {
                if(driverObj.getActiveStep().element == '#dd_project_filter_options' || driverObj.getActiveStep().element == '#projestleftlistfilter_driver') {
                    document.getElementById("projectrightsidedropdown_driver").click();
                    setTimeout(()=>{
                        driverObj.movePrevious();
                    })
                } else {
                    driverObj.movePrevious();
                }
            } else {
                driverObj.movePrevious();
            }
        } else if(fromTour == 'isTaskTour') {
            if(driverObj.isFirstStep() == false) {
                if(driverObj.getActiveStep().element === '#createtaskinput_driver') {
                    document.querySelector('#createtask_driver').click()
                    setTimeout(()=>{
                        driverObj.movePrevious()
                    })
                } else {
                    if(driverObj.getActiveStep().element === '#taskquickmenu_driver' || driverObj.getActiveStep().element === '#tasktoggle_driver') {
                        document.querySelector('#taskquickmenudriver').click()
                    }
                    if(driverObj.getActiveStep().element === '#subtasklist_driver' || driverObj.getActiveStep().element === undefined) {
                        document.querySelector('#tasktoggle_driver').click()
                    }
                    setTimeout(()=>{
                        driverObj.movePrevious()
                    })
                }
            }
        }
    }
    const startProjectTour = (key) => {
        if(companyUserDetail.value && (companyUserDetail.value.roleType === 1 || companyUserDetail.value.roleType === 2)) {
            if(clientWidth.value > 767) {
                let tours = handleTours(key)
                
                setTimeout(() => {
                    driverObj = driver(tours)
                    driverObj.drive();
                });
            }
        }
    }
    
    const hanldeProjectTour = (key) => {
        if(companyUserDetail.value && (companyUserDetail.value.roleType === 1 || companyUserDetail.value.roleType === 2)) {
            if(getUser(userId.value)?.tourStatus?.[key] == undefined || getUser(userId.value)?.tourStatus?.[key] === false || (getUser(userId.value)?.tourStatus == undefined || Object.keys(getUser(userId.value)?.tourStatus).length == 0)) {
                if(clientWidth.value > 767) {
                    if(key === 'isTaskTour') {
                        if(driverObj?.isActive() === true) {
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }else{
                return false;
            }
        }

    }

    return{
        handleTours,
        startProjectTour,
        closeIconTaskHandler,
        hanldeProjectTour,
        updateTourStatusInUser,
    }
}
